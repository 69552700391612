import React from "react"

import Layout from "components/Layout"
import { Main } from "page_components/404"

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} seo={{ title: "404: Not found" }}>
      <Main />
    </Layout>
  )
}

export default NotFoundPage
