import "./styles.scss"

import React from "react"

import Button from "components/Button"

import { useLocale } from "context/locale"

const Main = () => {
  const { t } = useLocale()

  return (
    <section className="not-found-main">
      <div className="container-fluid">
        <span>404</span>
        <p>
          {t(
            "Strona nie została znaleziona, przejdź na stronę główną bądź do wyszukiwarki mieszkań"
          )}
        </p>
        <Button to={t("/")} className="button--circle">
          {t("powrót")}
        </Button>
      </div>
    </section>
  )
}

export default Main
